/* General */
.u-flex--left, .u-flex--center, .u-flex--center-y, .u-flex--right, .u-flex--space-between {
  display: flex;
  align-items: center;
}
.u-flex--left.u-flex--inline, .u-flex--center.u-flex--inline, .u-flex--center-y.u-flex--inline, .u-flex--right.u-flex--inline, .u-flex--space-between.u-flex--inline {
  display: inline-flex;
}
.u-flex--left {
  justify-content: flex-start;
}
.u-flex--right {
  justify-content: flex-end;
}
.u-flex--center {
  justify-content: center;
}
.u-flex--space-between {
  justify-content: space-between;
}

/* Text */
.u-text--small {
  font-size: 12px;
}
.u-text--pre {
  white-space: pre-line;
}

/* to apply set theme='custom-theme' on JsonViewer component */
body.body--dark .jsonViewer {
  color: white !important;
  background-color: transparent;
}

body.body--light .jsonViewer {
  color: var(--q-dark) !important;
  background-color: transparent;
}

.source-container::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.source-container::-webkit-scrollbar-track {
  background: var(--scrollbar-track-bg-color);
}
.source-container::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-bg-color);
  border-radius: 0.375rem;
}

body.body--dark .source-container {
  background-color: black;
}

body.body--light .source-container {
  background-color: #eee;
}

.custom-theme {
  white-space: nowrap;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;
}
.custom-theme .jv-ellipsis {
  color: #999;
  background-color: #eee;
  display: inline-block;
  line-height: 0.9;
  font-size: 0.9em;
  padding: 0px 4px 2px 4px;
  border-radius: 3px;
  vertical-align: 2px;
  cursor: pointer;
  user-select: none;
}
.custom-theme .jv-button {
  color: #49b3ff;
}
.custom-theme .jv-item.jv-boolean {
  color: #fc1e70;
}
.custom-theme .jv-item.jv-function {
  color: #1306ca;
}
.custom-theme .jv-item.jv-number, .custom-theme .jv-item.jv-number-float, .custom-theme .jv-item.jv-number-integer {
  color: #fc1e70;
}
.custom-theme .jv-item.jv-undefined {
  color: #e08331;
}
.custom-theme .jv-item.jv-string {
  color: #42b983;
  word-break: break-word;
  white-space: normal;
}
.custom-theme .jv-code .jv-toggle:before {
  padding: 0px 2px;
  border-radius: 2px;
}
.custom-theme .jv-code .jv-toggle:hover .custom-theme .jv-code .jv-toggle:before {
  background: #eee;
}

body {
  --notify-success: var(--notify-success, #255B00);
  --notify-error: var(--notify-error, #880000);
  --notify-neutral: var(--notify-neutral, #4D4D4D);
  --notify-info: var(--notify-info, #008888);
}

.c-notify {
  --q-positive: var(--notify-success);
  width: 100%;
  max-width: 400px;
  margin: 1rem;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--bg-color, #fff);
  transition: all 0.3s ease;
  overflow: hidden;
}
.c-notify .q-notification__message {
  padding: 0;
}
.c-notify .q-notification__actions {
  justify-content: space-around;
  padding: 24px;
}
.c-notify .q-notification__actions .q-btn {
  font-weight: bold;
}
.c-notify .q-notification__actions .q-btn.text-positive i {
  margin-left: 0px;
  margin-top: -5px;
  transform: scale(0.5);
}
.c-notify__container {
  --c-notify__checkbox--display: none;
  --c-notify__message--align: center;
  display: flex;
  flex-direction: column;
}
.c-notify__container--success {
  --notify-color: var(--notify-success);
  --c-notify__title--display: flex;
  --c-notify__message--padding: 0 2rem;
  --c-notify__header--animation: consuming-bar 5s linear forwards;
}
.c-notify__container--error {
  --notify-color: var(--notify-error);
  --c-notify__title--display: flex;
  --c-notify__message--padding: 0 2rem;
}
.c-notify__container--info {
  --notify-color: var(--notify-info);
  --c-notify__title--display: flex;
  --c-notify__message--padding: 0 2rem;
  --c-notify__checkbox--display: block;
  --c-notify__message--align: left;
}
.c-notify__container--neutral {
  --notify-color: var(--notify-neutral);
  --c-notify__title--display: none;
  --c-notify__message--padding: 1.2rem 2rem;
  --c-notify__header--animation: consuming-bar 5s linear forwards;
}
.c-notify__checkbox-container {
  display: var(--c-notify__checkbox--display);
  margin: 2rem 0 0 2rem;
}
.c-notify__checkbox-container input {
  margin-right: 0.5rem;
}
.c-notify__checkbox-container label {
  color: var(--text-color, black);
  font-family: "silka-regular", "Roboto", sans-serif;
  font-size: 1rem !important;
  line-height: 150%;
}
.c-notify__header {
  height: 8px;
  background-color: var(--notify-color);
  animation: var(--c-notify__header--animation);
}
.c-notify__title {
  display: var(--c-notify__title--display);
  align-items: center;
  padding: 1rem;
  justify-content: center;
  font-size: 24px;
}
.c-notify__title img {
  width: 2.1rem;
  height: 2.1rem;
  margin-right: 0.3rem;
}
.c-notify__title span {
  color: var(--notify-color);
  font-weight: 600;
}
.c-notify__title--neutral {
  display: none;
}
.c-notify__message {
  color: var(--text-color, black);
  padding: var(--c-notify__message--padding);
  text-align: var(--c-notify__message--align, center);
}
.c-notify__message span {
  font-family: "silka-regular", "Roboto", sans-serif;
  font-size: 1rem !important;
  line-height: 150%;
}
.c-notify__message--subtitle {
  font-weight: bold;
  margin-bottom: 1rem;
}
.c-notify__action-btn {
  color: var(--text-color, black);
}
.c-notify__action-btn--hide {
  color: transparent;
  background-color: transparent;
  pointer-events: none;
}
.c-notify__dialog {
  width: 900px;
  max-width: calc(100vw - 48px) !important;
}

@keyframes consuming-bar {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
.q-layout__section--marginal {
  background-color: unset;
  color: unset;
}

.q-tooltip {
  font-size: 0.8rem;
}

body {
  --top-bar-height: 48px;
  --bottom-bar-height: 52px;
  font-size: 0.9em;
  font-family: "silkaregular", sans-serif;
}

body.body--light {
  --border-color: #eeeeee;
  --text-color: #1d1d1d;
  --subtitle-color: #757575;
  --bg-color: white;
  --bg-hover-color: rgb(233, 236, 239);
  --header-bg-color: {$grey-3};
  --muted-text-color: #eeeeee;
  --invert-text-color: white;
  --grey-text-color: #757575;
  --tab-bg-color: #eeeeee;
  --active-tab-bg-color: color-mix(in srgb, var(--q-primary), white 20%);
  --active-tab-text-color: white;
  --scrollbar-track-bg-color: #bdbdbd;
  --scrollbar-thumb-bg-color: #757575;
  --odd-row-bg-color: #eeeeee;
  --notify-success: #255B00;
  --notify-error: #880000;
  --notify-neutral: #4D4D4D;
  --notify-info: #008888;
  --pagination-buttons-color: #616161;
}
body.body--light a:hover {
  color: color-mix(in srgb, var(--q-primary), black 50%);
}

body.body--dark {
  --border-color: #424242;
  --text-color: white;
  --subtitle-color: #bdbdbd;
  --bg-color: #181818;
  --bg-hover-color: #282828;
  --header-bg-color: #212121;
  --muted-text-color: #616161;
  --invert-text-color: #1d1d1d;
  --grey-text-color: #bdbdbd;
  --tab-bg-color: #212121;
  --active-tab-bg-color: var(--q-primary);
  --active-tab-text-color: white;
  --scrollbar-track-bg-color: #212121;
  --scrollbar-thumb-bg-color: #757575;
  --odd-row-bg-color: #212121;
  --q-dark: #181818;
  --notify-success: #469d0d;
  --notify-error: #b30909;
  --notify-neutral: #858585;
  --notify-info: #15baba;
  --pagination-buttons-color: #616161;
}
body.body--dark a:hover {
  color: color-mix(in srgb, var(--q-primary), white 30%);
}
body.body--dark .vjs-tree-node.is-highlight, body.body--dark .vjs-tree-node:hover {
  background-color: #313436;
}

a {
  color: var(--q-primary);
  text-decoration: none;
  cursor: pointer;
}

.q-tabs {
  max-width: 100%;
}

.q-tab--inactive {
  opacity: unset;
}

.q-tab-panels {
  background: none;
}

.q-card {
  border-radius: 12px;
}

.q-panel.scroll {
  overflow: var(--v-overflow, scroll);
}

.q-table__middle::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.q-table__middle::-webkit-scrollbar-track {
  background: var(--scrollbar-track-bg-color);
}
.q-table__middle::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-bg-color);
  border-radius: 0.375rem;
}

.q-card--dark {
  box-shadow: 0 0.5rem 1.2rem rgba(82, 85, 85, 0.1);
}

.q-expansion-item .q-item {
  background: #fff;
}
.q-expansion-item .q-item--dark {
  background: #212121;
}

.q-tabs__arrow {
  top: 12px !important;
}
.q-tabs__arrow--right {
  top: 12px !important;
}